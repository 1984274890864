//const CLIENT_ID = process.env.CLIENT_ID || '0oao2tpdb5o21vhiq5d7';

//prod client id
const CLIENT_ID = process.env.CLIENT_ID || '0oa1mu1frfrqwBhGI1d8';

//const ISSUER = process.env.ISSUER || 'https://dev-10086979.okta.com/oauth2/default';

const ISSUER = process.env.ISSUER || 'https://cbsi.okta.com/oauth2/default';
  
//https://cbsi.okta.com/oauth2/default
const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;
const REDIRECT_URI = `${window.location.origin}/login/callback`;

// eslint-disable-next-line
export default {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email','offline_access'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  },
  resourceServer: {
    messagesUrl: 'http://localhost:8000/api/messages',
  },
};
