// CustomMediaPlanBuilder.jsx
import React, { useState } from 'react';

const CustomMediaPlanBuilder = () => {
  // Each row includes: id, groupId (null if ungrouped), placement (Content Targeting),
  // platform (Platform Targeting), rate (computed, read-only), quantity.
  // (The "flight" field is no longer rendered.)
  const [rows, setRows] = useState([
    { id: 1, groupId: null, placement: '', platform: '', rate: 0, quantity: 0, flight: '' },
  ]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [groupNames, setGroupNames] = useState({});

  // Helper: Compute Rate based on Content & Platform selections.
  // Default is $12; if a Content Targeting option is selected then $22;
  // if a Platform Targeting option is selected then:
  // "Paramount+ Only" → $20, "Paramount+ CTV Extension" → $17, "PlutoTV Only" → $13.
  const computeRate = (row) => {
    const defaultRate = 12;
    if (row.placement !== "") {
      return defaultRate + 10; // 12 + 10 = 22
    } else if (row.platform !== "") {
      const mapping = {
        "Paramount+ Only": 20,
        "Paramount+ CTV Extension": 17,
        "PlutoTV Only": 13,
      };
      return mapping[row.platform] || defaultRate;
    } else {
      return defaultRate;
    }
  };

  // Net Cost = (computed rate * quantity) / 1000.
  const computeNetCost = (row) => {
    return ((computeRate(row) * row.quantity) / 1000).toFixed(2);
  };

  // Toggle row selection.
  const toggleSelectRow = (id, checked) => {
    setSelectedIds(prev => (checked ? [...prev, id] : prev.filter(sid => sid !== id)));
  };

  // Update a specific field in a row.
  const updateRow = (id, field, value) => {
    setRows(prev => prev.map(row => (row.id === id ? { ...row, [field]: value } : row)));
  };

  // Add a new row.
  const addRow = () => {
    const newRow = {
      id: Date.now(),
      groupId: null,
      placement: '',
      platform: '',
      rate: 0,
      quantity: 0,
      flight: '',
    };
    setRows(prev => [...prev, newRow]);
  };

  // Revised Group Selected:
  // If any selected row is already grouped, merge them; otherwise create a new group.
  const groupSelected = () => {
    if (selectedIds.length === 0) return;
    const selectedGroupIds = new Set(
      rows
        .filter(row => selectedIds.includes(row.id) && row.groupId !== null)
        .map(row => row.groupId)
    );
    let targetGroupId;
    if (selectedGroupIds.size > 0) {
      targetGroupId = Array.from(selectedGroupIds)[0];
    } else {
      targetGroupId = `group-${Date.now()}`;
      setGroupNames(prev => ({ ...prev, [targetGroupId]: '' }));
    }
    setRows(prev =>
      prev.map(row =>
        selectedIds.includes(row.id) || selectedGroupIds.has(row.groupId)
          ? { ...row, groupId: targetGroupId }
          : row
      )
    );
    // Remove extra group names if merging groups.
    setGroupNames(prev => {
      const newGroupNames = { ...prev };
      selectedGroupIds.forEach(gid => {
        if (gid !== targetGroupId && newGroupNames[gid] !== undefined) {
          delete newGroupNames[gid];
        }
      });
      return newGroupNames;
    });
    setSelectedIds([]);
  };

  // Ungroup selected rows.
  // If any selected row belongs to a group, ungroup all rows in that group.
  const ungroupSelected = () => {
    if (selectedIds.length === 0) return;
    setRows(prevRows => {
      const groupsToUngroup = new Set();
      prevRows.forEach(row => {
        if (selectedIds.includes(row.id) && row.groupId !== null) {
          groupsToUngroup.add(row.groupId);
        }
      });
      const newRows = prevRows.map(row =>
        groupsToUngroup.has(row.groupId) ? { ...row, groupId: null } : row
      );
      setGroupNames(prevGroupNames => {
        const newGroupNames = { ...prevGroupNames };
        groupsToUngroup.forEach(groupId => {
          if (newGroupNames[groupId]) {
            console.log('Removing group:', groupId);
            delete newGroupNames[groupId];
          }
        });
        return newGroupNames;
      });
      return newRows;
    });
    setSelectedIds([]);
  };

  // Update all rows in a group for a given field.
  const updateGroupField = (groupId, field, value) => {
    setRows(prev =>
      prev.map(row => (row.groupId === groupId ? { ...row, [field]: value } : row))
    );
  };

  // Update group name.
  const updateGroupName = (groupId, newName) => {
    setGroupNames(prev => ({ ...prev, [groupId]: newName }));
  };

  // Sort rows so that grouped rows appear together.
  const sortedRows = [...rows].sort((a, b) => {
    if (a.groupId && b.groupId) return a.groupId.localeCompare(b.groupId) || a.id - b.id;
    if (a.groupId) return -1;
    if (b.groupId) return 1;
    return a.id - b.id;
  });

  // Build table rows.
  const renderRows = () => {
    const output = [];
    const renderedGroups = new Set();
    sortedRows.forEach(row => {
      const isGrouped = row.groupId !== null;
      let rowSpan = 1;
      if (isGrouped) {
        rowSpan = sortedRows.filter(r => r.groupId === row.groupId).length;
      }
      let groupNameRow = null;
      if (isGrouped && !renderedGroups.has(row.groupId)) {
        renderedGroups.add(row.groupId);
        groupNameRow = (
          <tr key={`group-name-${row.groupId}`} style={{ backgroundColor: '#eef' }}>
            <td colSpan="7" style={{ padding: '8px' }}>
              <strong>Group Name:</strong>{' '}
              <input
                type="text"
                value={groupNames[row.groupId] || ''}
                onChange={e => updateGroupName(row.groupId, e.target.value)}
                style={{ width: '60%' }}
                placeholder="Enter a group name"
              />
            </td>
          </tr>
        );
        output.push(groupNameRow);
      }

      // For grouped rows, merge these cells (only in the first row of the group):
      // Select, Brand, Rate, Quantity, and Net Cost.
      let mergedSelectCell = null;
      let mergedBrandCell = null;
      let mergedRateCell = null;
      let mergedQuantityCell = null;
      let mergedNetCostCell = null;
      if (isGrouped) {
        const groupRows = sortedRows.filter(r => r.groupId === row.groupId);
        if (groupRows[0].id === row.id) {
          mergedSelectCell = (
            <td rowSpan={rowSpan}>
              <input
                type="checkbox"
                checked={selectedIds.includes(row.id)}
                onChange={e => toggleSelectRow(row.id, e.target.checked)}
              />
            </td>
          );
          mergedBrandCell = <td rowSpan={rowSpan}>Paramount Streaming</td>;
          mergedRateCell = (
            <td rowSpan={rowSpan}>
              ${computeRate(row)}
            </td>
          );
          mergedQuantityCell = (
            <td rowSpan={rowSpan}>
              <input
                type="number"
                value={row.quantity}
                onChange={e =>
                  updateGroupField(row.groupId, 'quantity', Number(e.target.value))
                }
              />
            </td>
          );
          mergedNetCostCell = (
            <td rowSpan={rowSpan}>
              ${computeNetCost(row)}
            </td>
          );
        }
      }

      // For ungrouped rows.
      const nonMergedSelect = (
        <td>
          <input
            type="checkbox"
            checked={selectedIds.includes(row.id)}
            onChange={e => toggleSelectRow(row.id, e.target.checked)}
          />
        </td>
      );
      const nonMergedBrand = <td>Paramount Streaming</td>;
      const nonMergedContent = (
        <td>
          <select
            value={row.placement}
            onChange={e => updateRow(row.id, 'placement', e.target.value)}
            disabled={row.platform !== ""}
          >
            <option value="">Select Content Targeting</option>
            <option value="Prime">Prime</option>
            <option value="Plus">Plus</option>
            <option value="Comedy">Comedy</option>
            <option value="Kids & Family">Kids & Family</option>
            <option value="Late Night">Late Night</option>
            <option value="Culture">Culture</option>
            <option value="News">News</option>
            <option value="Latino">Latino</option>
          </select>
        </td>
      );
      const nonMergedPlatform = (
        <td>
          <select
            value={row.platform}
            onChange={e => updateRow(row.id, 'platform', e.target.value)}
            disabled={row.placement !== ""}
          >
            <option value="">Select Platform Targeting</option>
            <option value="Paramount+ Only">Paramount+ Only</option>
            <option value="Paramount+ CTV Extension">Paramount+ CTV Extension</option>
            <option value="PlutoTV Only">PlutoTV Only</option>
          </select>
        </td>
      );
      const nonMergedRate = (
        <td>
          ${computeRate(row)}
        </td>
      );
      const nonMergedQuantity = (
        <td>
          <input
            type="number"
            value={row.quantity}
            onChange={e => updateRow(row.id, 'quantity', Number(e.target.value))}
          />
        </td>
      );
      const nonMergedNetCost = (
        <td>
          ${computeNetCost(row)}
        </td>
      );

      // Build the row element.
      if (isGrouped) {
        if (sortedRows.filter(r => r.groupId === row.groupId)[0].id === row.id) {
          output.push(
            <tr key={row.id}>
              {mergedSelectCell}
              {mergedBrandCell}
              {nonMergedContent}
              {nonMergedPlatform}
              {mergedRateCell}
              {mergedQuantityCell}
              {mergedNetCostCell}
            </tr>
          );
        } else {
          output.push(
            <tr key={row.id}>
              {nonMergedContent}
              {nonMergedPlatform}
            </tr>
          );
        }
      } else {
        output.push(
          <tr key={row.id}>
            {nonMergedSelect}
            {nonMergedBrand}
            {nonMergedContent}
            {nonMergedPlatform}
            {nonMergedRate}
            {nonMergedQuantity}
            {nonMergedNetCost}
          </tr>
        );
      }
    });
    return output;
  };

  return (
    <div style={{ padding: '16px' }}>
      <h2>Custom Media Plan Builder</h2>
      <div style={{ marginBottom: '16px' }}>
        <button onClick={groupSelected} disabled={!selectedIds.length}>
          Group Selected
        </button>
        <button onClick={ungroupSelected} disabled={!selectedIds.length} style={{ marginLeft: '8px' }}>
          Ungroup Selected
        </button>
        <button onClick={addRow} style={{ marginLeft: '8px' }}>
          Add Row
        </button>
      </div>
      <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
        <thead>
          <tr>
            <th style={{ width: '40px' }}></th>
            <th>Brand</th>
            <th>Content Targeting</th>
            <th>Platform Targeting</th>
            <th>Rate</th>
            <th>Quantity</th>
            <th>Net Cost</th>
          </tr>
        </thead>
        <tbody>{renderRows()}</tbody>
      </table>
    </div>
  );
};

export default CustomMediaPlanBuilder;
