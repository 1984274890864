import React, {useEffect, useState} from 'react'

import Box from "@mui/material/Box";
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from "react-router-dom";
import LoadingButton from '@mui/lab/LoadingButton';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useLocalStorage } from "../hooks/useLocalStorage";
import { useOktaAuth } from '@okta/okta-react';
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const salespeople = [
  { id:2, salesperson:'Adele Weiss'},
  { id:3, salesperson:'Alan Goldstein'},
  { id:5, salesperson:'Alessandra Wendroff'},
  { id:6, salesperson:'Alexander Wolfe'},
  { id:7, salesperson:'Allasandrina Soffa'},
  { id:8, salesperson:'Amanda Gardley'},
  { id:10, salesperson:'Arielle Moore'},
  { id:12, salesperson:'Bianca Marcazzo'},
  { id:15, salesperson:'Brittany Tessler'},
  { id:16, salesperson:'Cailin Doherty'},
  { id:17, salesperson:'Caroline Coyer'},
  { id:19, salesperson:'Christina Muranelli'},
  { id:20, salesperson:'Christopher Diaz'},
  { id:22, salesperson:'Courtney Battle'},
  { id:24, salesperson:'Danielle Mudge'},
  { id:25, salesperson:'Dave Frieder'},
  { id:27, salesperson:'Debra Ashley'},
  { id:28, salesperson:'Dennis Burger'},
  { id:29, salesperson:'Derek Raneri'},
  { id:30, salesperson:'Elizabeth Sloan'},
  { id:33, salesperson:'Erin Whelan'},
  { id:34, salesperson:'Evan Noonan'},
  { id:35, salesperson:'Gaby Markey'},
  //{ id:37, salesperson:'Giancarlo Silva'},
  { id:40, salesperson:'Henry Palermo'},
  { id:42, salesperson:'Jacqueline Helweil'},
  { id:45, salesperson:'Jason Laderman'},
  { id:46, salesperson:'Jason Mezic'},
  { id:48, salesperson:'Jennifer Paulenoff'},
  { id:50, salesperson:'Jessica Lieberman'},
  { id:51, salesperson:'Jessie Koverman'},
  //{ id:52, salesperson:'Jim Cassaro'},
  { id:54, salesperson:'John Winchell'},
  { id:55, salesperson:'Jonathan Wesley'},
  { id:56, salesperson:'Jordan Gipson'},
  { id:58, salesperson:'Justen Creppy'},
  { id:61, salesperson:'Kara Tricarico'},
  { id:62, salesperson:'Karla Nielsen'},
  { id:64, salesperson:'Kerry Zuaro'},
  { id:65, salesperson:'Kevin Feinstein'},
  { id:67, salesperson:'Kristen Albanese'},
  { id:68, salesperson:'Kristin Rachlin'},
  { id:69, salesperson:'Kurt Hutchinson'},
  { id:71, salesperson:'Lauren Morris'},
  { id:75, salesperson:'Lyset Monson'},
  { id:76, salesperson:'Maggie Lessing'},
  { id:79, salesperson:'Mark Rodas'},
  { id:82, salesperson:'Megan O\'Brien'},
  { id:84, salesperson:'Michael Elias'},
  { id:85, salesperson:'Michael Mueller'},
  { id:87, salesperson:'Morgan Driscoll'},
  { id:90, salesperson:'Paul Mugler'},
  { id:92, salesperson:'Raymond Semente'},
  { id:93, salesperson:'Regina Conlin'},
  { id:94, salesperson:'Ryan Murray'},
  { id:95, salesperson:'Sanya Lewin'},
  { id:96, salesperson:'Sarik Kumar'},
  { id:97, salesperson:'Sawyer Harris'},
  { id:98, salesperson:'Shakira Hill'},
  { id:99, salesperson:'Shera Mayer'},
  { id:101, salesperson:'Stephanie Kahn Woods'},
  { id:102, salesperson:'Stephanie Leslie'},
  { id:104, salesperson:'Tamara Jones'},
  { id:107, salesperson:'Wanda Sutherland'},
  { id:141, salesperson:'Joy Sipple'},
  { id:38, salesperson:'CBS Local - Detroit'},
  { id:149, salesperson:'CBS Local - New York'},
  { id:161, salesperson:'CBS Local - Sacramento'},
  { id:173, salesperson:'CBS Local - Minneapolis'},
  { id:190, salesperson:'Darryl Hendricks'},
  { id:194, salesperson:'CBS Local - Pittsburgh'},
  { id:202, salesperson:'CBS Local - Atlanta'},
  { id:212, salesperson:'CBS Local - Denver'},
  { id:219, salesperson:'CBS Local - Chicago'},
  { id:237, salesperson:'CBS Local - Baltimore'},
  { id:238, salesperson:'CBS Local - Tampa'},
  { id:239, salesperson:'CBS Local - Boston'},
  { id:241, salesperson:'CBS Local - Seattle'},
  { id:244, salesperson:'CBS Local - Philadelphia'},
  { id:250, salesperson:'CBS Local - Dallas'},
  { id:259, salesperson:'CBS Local - Miami'},
  { id:280, salesperson:'CBS Local - Los Angeles'},
  { id:287, salesperson:'CBS Local - San Francisco'},
  { id:290, salesperson:'Meaghan Black'},
  { id:293, salesperson:'Siobhan Elvin'},
  { id:297, salesperson:'Rachel Oliveros'},
  { id:298, salesperson:'Kendall Doherty'},
  { id:300, salesperson:'Samantha Faust'},
  { id:301, salesperson:'Colleen Gerndt'},
  { id:303, salesperson:'Patrick Salter'},
  { id:304, salesperson:'Jessica Assaad'},
  { id:305, salesperson:'Stephen Russell'},
  { id:306, salesperson:'Jessica Heard'},
  { id:307, salesperson:'Nicholas Williams'},
  { id:308, salesperson:'Odean Mangum'},
  { id:309, salesperson:'Alden Geller'},
  { id:310, salesperson:'Chris Reil'},
  { id:311, salesperson:'Cristina Falbo'},
  { id:317, salesperson:'Craig Harrison'},
  { id:319, salesperson:'Sean Sultanik'},
  { id:320, salesperson:'Amil Shah'},
{ id:321, salesperson:'Christa Thompson'},
{ id:322, salesperson:'Enitan Fe-Cados'},
{ id:323, salesperson:'Michelle Edobor'},
{ id:324, salesperson:'Mike Thomas'},
{ id:325, salesperson:'Robin Simpson'},
{ id:326, salesperson:'Victoria Ivanova'},
{ id:327, salesperson:'Zach Belmont'},
{ id:328, salesperson:'Jennifer L Bialowarczuk'},
{ id:329, salesperson:'Emileigh Gaeta'},
{ id:330, salesperson:'Nicole Cors'},
{ id:331, salesperson:'Erin Kruger'},
{ id:21, salesperson:'Conor Kennedy'},
{ id:332, salesperson:'Tara Danels'},
{ id:333, salesperson:'Amanda Giudice'},
{ id:334, salesperson:'Marissa Baum'},
{ id:336, salesperson:'Rachel Hoffman'},
{ id:337, salesperson:'Brielle Mollegard'},
{ id:338, salesperson:'Jordyn Weinstein'},
{ id:339, salesperson:'Maggie Seligman'},
{ id:340, salesperson:'Nicholas Giannitelli'},
{ id:341, salesperson:'Olsen Samedy'},


]
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

//const theme = createTheme();
export const ProfilePage = () => {
  
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState([]);
  const [role, setRole] = useState('');
  const [defaultDashboard, setDefaultDashboard] = useLocalStorage("dashboard", null);

  const [defaultAssignments, setDefaultAssignments] = useState([])

  const [saving, setSaving] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [snackOpen, setSnackOpen] = React.useState(false);

  salespeople.sort((a,b) => (a.salesperson > b.salesperson) ? 1 : ((b.salesperson > a.salesperson) ? -1 : 0))



  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!authState || !authState.isAuthenticated) {
        // When user isn't authenticated, forget any user info
        setUserInfo(null);
      } else {
        try {
          let accessToken = authState.accessToken;
  
          // Check if the token is expired and refresh it if necessary
          if (oktaAuth.tokenManager.hasExpired(accessToken)) {
            accessToken = await oktaAuth.tokenManager.renew('accessToken');
          }
  
          setUserInfo(authState.idToken.claims);
          const options = {
            method: 'GET',
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + accessToken.accessToken
            }
          };
  
          const response = await fetch("/user/" + authState.idToken.claims.sub, options);
          const data = await response.json();
  
          // Process your data here
          let assignments = [];
          salespeople.forEach(function (arrayItem) {
            if (data.assignments.includes(arrayItem.id)) {
              assignments.push(arrayItem);
            }
          });
  
          setDefaultAssignments(assignments);
          let role = localStorage.getItem("role");
          const roleParsed = JSON.parse(role);
          setRole(roleParsed);
          setLoading(false);
  
        } catch (error) {
          console.error('Error fetching data: ', error);
          // Handle errors such as network issues, parsing issues, etc.
        }
      }
    };
  
    fetchData();

    
  }, [authState, oktaAuth]); // Update if authState changes

/*

  useEffect(() => {
    let assignmentArray = JSON.parse(localStorage.getItem('dashboard'))//
    let assignments = []
        
    salespeople.forEach(function (arrayItem) {
      if (assignmentArray.includes(arrayItem.id)){
        assignments.push(arrayItem)
        
      }
    })
      setDefaultAssignments(assignments)



    //setDefaultAssignments(assignmentArray)
    setLoading(false)

}, [])  

*/



const handleSave = async () => {
  if (value.length > 0) {
    setSaving(true);

    let result = value.map(a => a.id);
    try {
      let accessToken = authState.accessToken;

      // Check if the token is expired and refresh it if necessary
      if (oktaAuth.tokenManager.hasExpired(accessToken)) {
        accessToken = await oktaAuth.tokenManager.renew('accessToken');
      }

      var options = {
        method: 'POST',
        body: JSON.stringify({
          assignments: result, uid: userInfo.sub
        }),
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + accessToken.accessToken
        }
      };

      const response = await fetch("/update_assignments", options);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      if (response.headers.get("content-type")?.includes("application/json")) {
        const body = await response.json();
        // Process the JSON response here
        console.log(body);
      } else {
        //console.log('Received non-JSON response');
        // Handle non-JSON responses or no content here
      }

      setDefaultDashboard(result);
      setSnackOpen(true);
      setSaving(false);
      setButtonDisabled(true);

    } catch (error) {
      console.error('Error in handleSave: ', error);
      setSaving(false);
    }
  }
};



  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackOpen(false);
  };





  if (loading) {


    return (    
<Box sx={{ backgroundColor: '#ffffff' }}>
               {/* <ThemeProvider theme={theme}>*/}
  <Container component="main" maxWidth="xs">
    <CssBaseline />
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
                    <CircularProgress />
                    </Box>
                    </Container>
{/*</ThemeProvider>*/}

            </Box>   
                    )
    } else {
        return (



            
            <Box sx={{ backgroundColor: '#ffffff' }}>
                  <Grid container rowSpacing={0} columnSpacing={{ xs: 0}} alignItems="center">
                  <Grid item xs={0.15}>
    </Grid> 
    <Grid item xs={11.85}>
    <Typography variant="body2" marginTop={0} display="flex">

    <Link fontSize="small" style={{
display: 'flex',
alignItems: 'center',
paddingLeft:'20px',
backgroundColor: '#ffffff',
color: '#000787',
fontWeight:500
}} to={`/dashboard`}>Home</Link> 
            
<Link fontSize="small" style={{
display: 'flex',
alignItems: 'center',
paddingLeft:'0px',
backgroundColor: '#ffffff',
color: '#000787',
fontWeight:500
}} to={``}><NavigateNextIcon fontSize="small" />Profile</Link> 
            
         </Typography>   
        </Grid>

</Grid>   
              {/*  <ThemeProvider theme={theme}> */}
  <Container component="main" maxWidth="xs">
    <CssBaseline />
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
        <PersonIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Profile
      </Typography>
      <br></br>
      <Typography variant="body2" align="center">
      </Typography>






      </Box>
      <TextField
      disabled
          label="Name"
          id="standard-size-normal"
          defaultValue={userInfo.name}
          variant="standard"
          fullWidth
        />
        <p></p>
              <TextField
      disabled
          label="Email"
          id="standard-size-normal"
          defaultValue={userInfo.email}
          variant="standard"
          fullWidth
        />
        <p></p>
              <TextField
      disabled
          label="Role"
          id="standard-size-normal"
          defaultValue={role}
          variant="standard"
          fullWidth
        />

<p></p>
 
<Autocomplete
        multiple
        id="tags-standard"
        options={salespeople}
        isOptionEqualToValue={(option, value) => option.id === value.id}

        onChange={(event, newValue) => {


          setButtonDisabled(false)
          setValue(newValue);
          setDefaultAssignments(newValue)
        }}
        getOptionLabel={(option) => option.salesperson}
        value={defaultAssignments}
        renderTags={(tagValue, getTagProps) => {
          return tagValue.map((option, index) => (
            <MyChip {...getTagProps({ index })} label={option.salesperson} />
          ));
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Sales Books"
            placeholder=""
          />
        )}
      />
           <LoadingButton fullWidth
          variant="contained"
          onClick={handleSave}
          disabled={buttonDisabled}
          loading={saving}
          sx={{ mt: 3, mb: 2 }}>Update</LoadingButton>
  </Container>
{/*</ThemeProvider>*/}
<Snackbar open={snackOpen} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Profile updated!
        </Alert>
      </Snackbar>
            </Box>   
        )
    }
  
}
const MyChip = (props) => {

return (
    <Chip
    color="primary"
    size="small"
            {...props}
    />
);
};